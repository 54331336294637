<template>
  <Html :lang="head.htmlAttrs?.lang" :dir="head.htmlAttrs?.dir">
    <Head>
      <template v-for="link in head.link" :key="link.id">
        <Link
          :id="link.id"
          :rel="link.rel"
          :href="link.href"
          :hreflang="link.hreflang"
        />
      </template>
      <template v-for="meta in head.meta" :key="meta.id">
        <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
      </template>
    </Head>
    <Body>
      <div class="page-wrapper">
        <NuxtLazyHydrate when-idle>
          <NavigationHeader />
        </NuxtLazyHydrate>

        <main class="main-content">
          <NuxtLazyHydrate when-idle>
            <NavigationBreadcrumbs />
          </NuxtLazyHydrate>

          <slot />
        </main>
        <NuxtLazyHydrate when-visible>
          <NavigationFooter />
        </NuxtLazyHydrate>
      </div>
    </Body>
  </Html>
</template>

<script lang="ts" setup>
import { useA11y } from '@/composables/useA11y'
import { useIsMobile } from '@/composables/useIsMobile'
import { useIsSafari } from '@/composables/useIsSafari'

useIsMobile()
useA11y()
useIsSafari()

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'key',
  addSeoAttributes: true
})

const { data: seoSettings } = await useAsyncData('seoSettings', () =>
  useApi().publicCoreSeoSettingsRetrieve()
)

const preparedSEOHead = usePrepareSEOHead(seoSettings)

const {
  public: { COOKIEBOT_ID }
} = useRuntimeConfig()

useHead({
  script: COOKIEBOT_ID
    ? [
        {
          // Reserve top of head spot on server and load on client
          key: 'Cookiebot',
          tagPriority: -30
        }
      ]
    : [],
  title: seoSettings.value?.meta_title,
  titleTemplate: seoSettings.value?.meta_title_template,
  ...preparedSEOHead
})

useHead(
  {
    script: COOKIEBOT_ID
      ? [
          {
            key: 'Cookiebot',
            id: 'Cookiebot',
            tagPriority: -30,
            src: 'https://consent.cookiebot.com/uc.js',
            'data-cbid': COOKIEBOT_ID,
            'data-blockingmode': 'manual',
            type: 'text/javascript'
          }
        ]
      : []
  },
  { mode: 'client' }
)
</script>

<style lang="scss">
.main-content {
  transition: opacity $transition;

  .route-change & {
    opacity: 0;
  }

  body:not(.route-change) & {
    opacity: 1;
  }
}
</style>

<style lang="scss" scoped>
.main-content {
  position: relative;
  filter: none;

  &::after {
    @include fill-space;
    z-index: 2;
    pointer-events: none;
    content: '';
    background-color: black;
    opacity: 0;
    transition: opacity $transition;
  }

  .dropdown-active &::after {
    opacity: 0.5;
  }
}
</style>
