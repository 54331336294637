import { useContrastMode, useFontSize } from '@/state/a11yState'

export function useA11y() {
  const contrastMode = useContrastMode()
  const fontSize = useFontSize()

  useHead({
    htmlAttrs: () => ({
      class: `font-${fontSize.value}${
        contrastMode.value ? ' contrast-mode' : ''
      }`
    })
  })
}
